<template>
    <div :class="'deals-timer-' + color">
        <v-tooltip bottom color="secondary" max-width="220px" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon
                        size="18"
                        :color="color"
                        style="margin-bottom: 3px"
                    >
                        {{ icons.mdiTimerOutline }}
                    </v-icon>
                    <span>{{ days + 'd ' + hours + 'h ' + minutes + 'min ' }}</span>
                </div>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mdiTimerOutline } from '@mdi/js';
let interval = null;

export default {
    name: 'CountDownTimer',
    setup() {
        return {
            icons: {
                mdiTimerOutline
            }
        }
    },
    props: {
        end: {
            type: String
        },
        stop: {
            type: Boolean
        },
        tooltip: {
            type: String
        },
        color: {
            type: String
        }
    },
    data() {
        return {
            now: Math.trunc(((new Date()).getTime() - Math.abs(((new Date()).getTimezoneOffset() * 60000))) / 1000),
            date: null,
            diff: 0
        }
    },
    created() {
        if (!this.end) throw new Error("Missing props 'end'")
        this.date = Math.trunc(Date.parse(this.end) / 1000)
        if (!this.date) throw new Error("Invalid props value, correct the 'end'")
        interval = setInterval(() => {
            this.now = Math.trunc(((new Date()).getTime() - Math.abs(((new Date()).getTimezoneOffset() * 60000))) / 1000)
        }, 1000);
    },
    computed: {
        minutes() {
            return Math.trunc(this.diff / 60) % 60
        },
        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24
        },
        days() {
            return Math.trunc(this.diff / 60 / 60 / 24)
        }
    },
    watch: {
        now(value) {
            this.diff = this.date - this.now;
            if(this.diff <= 0 || this.stop){
                this.diff = 0;
                // Remove interval
                clearInterval(interval);
            }
        }
    },
    destroyed() {
        clearInterval(interval);
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.getOrderRefHeadersByRouteName(_vm.$route.name),"items":_vm.orders,"options":_vm.options,"server-items-length":_vm.totalOrders,"loading":_vm.loading,"footer-props":{
    'items-per-page-options': _vm.$store.getters['nav/itemsPerPageOptions'],
    'disable-pagination': _vm.loading,
    'disable-items-per-page': _vm.loading,
  },"mobile-breakpoint":100,"items-per-page":_vm.globalItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.getOrdersFromApi,"update:sort-desc":_vm.getOrdersFromApi},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticStyle:{"width":"90vw","max-width":"1390px"},attrs:{"type":"table-row-divider@5"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-8 mb-8"},[_c('EmptyTableIcon'),_c('p',{staticClass:"empty-title"},[_vm._v(_vm._s(_vm.$t('Your list is empty.')))]),(_vm.isBuyer(_vm.$route.name))?_c('v-btn',{attrs:{"color":"primary","to":{name: 'products'}}},[_vm._v(" "+_vm._s(_vm.$t('Buy Deals'))+" ")]):(!_vm.$route.name.includes('refunds') && !_vm.$route.name.includes('admin'))?_c('create-product',{attrs:{"buttonText":"Create Product"}}):_vm._e()],1)]},proxy:true},{key:"header.source_link",fn:function(ref){
  var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.buy_box_price",fn:function(ref){
  var header = ref.header;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"item.id",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: _vm.relativeLink(_vm.$route.name), params: {id: item.id}}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.dispute_id",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: _vm.relativeLink(_vm.$route.name), params: {id: item.id}}}},[_vm._v(_vm._s(item.dispute_id))])]}},{key:"item.dispute_date",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dispute_date.slice(0, 10)))])]}},{key:"item.keepa_image",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: _vm.orderLink, params:{id: item.id}}}},[_c('v-img',{staticClass:"my-2",staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.15) 0px 2px 8px","border-radius":"5px"},attrs:{"max-height":"50","max-width":"80","src":item.keepa_image||_vm.noKeepaImage}})],1)]}},{key:"item.image",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: _vm.orderLink, params:{id: item.id}}}},[_c('v-img',{staticClass:"my-2",staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.15) 0px 2px 8px","border-radius":"5px"},attrs:{"max-height":"50","max-width":"70","src":item.image}})],1)]}},{key:"item.source_link",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(item.asin)?_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"width":"fit-content"},attrs:{"href":item.product_url,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.asin)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s('ASIN'))])]):_vm._e(),(_vm.cutString(item.source_link).includes('...'))?_c('v-tooltip',{attrs:{"max-width":"330px","top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.source_link,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.source_link))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.source_link.slice(0, 100)))])]):_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.source_link,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.cutString(item.source_link))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Source')))])])],1)]}},{key:"item.seller",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.seller?item.seller.user_id:'', tab: 'store'}}}},[_vm._v(_vm._s(item.seller?item.seller.title:''))])]}},{key:"item.product_id",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'product', params: {id: item.product_id}}}},[_vm._v(_vm._s(item.product_id))])]}},{key:"item.store",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'store-products', params: {slug: item.store_slug}}}},[_vm._v(_vm._s(item.store_title))])]}},{key:"item.buyer",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.buyer?item.buyer.id:''}}}},[_vm._v(_vm._s(item.buyer?item.buyer.name:''))])]}},{key:"item.price",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(("$" + (Number(item.price)))))])]}},{key:"item.buy_box_price",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"font-size":"12px"}},[_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(Number(item.buy_box_price) ? '$' + Number(item.buy_box_price) : _vm.$t("No BuyBox")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s('BuyBox price'))])]),_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('b',[_vm._v("COG:")]),_vm._v(" "+_vm._s(("$" + (Number(item.cog)))))])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Cost of goods'))])]),_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('b',[_vm._v("3PL:")]),_vm._v(" "+_vm._s(Number(item.third_pl) ? ("$" + (Number(item.third_pl))) : '-'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s('Third-party logistics'))])])],1)]}},{key:"item.profit_fba",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.profit_fba) ? ("$" + (Number(item.profit_fba))) : '-')+" ")]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [(_vm.$route.name.includes('seller'))?_c('div',[(_vm.status[item.status])?_c('v-chip',{staticClass:"font-weight-semibold py-3",class:{'mt-2': _vm.isTimer(item) && item.status == 2},attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")]):_vm._e(),(_vm.isTimer(item) && item.status == 2)?_c('count-down-timer',{staticClass:"mt-1",attrs:{"end":item.order_history[0].date_end,"color":"error","tooltip":'You have ' + item.refund_expired_period + ' days to make a decision. Otherwise the order will be refunded automatically.'}}):_vm._e()],1):_vm._e(),(_vm.$route.name == 'orders-list' || _vm.$route.name == 'refunds-list')?_c('div',[(_vm.status[item.status] && item.status != 2 && item.status != 5 && item.status != 3)?_c('v-chip',{staticClass:"font-weight-semibold py-3",attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")]):(_vm.isTimer(item))?_c('v-tooltip',{attrs:{"max-width":"250px","bottom":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(_vm.status[item.status])?_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-semibold py-3",attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")]):_vm._e()]}}],null,true)},[_c('span',[(item.order_history[0])?_c('count-down-timer',{attrs:{"end":item.order_history[0].date_end,"color":"white","tooltip":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.getTimerTooltipBuyer(item)))])],1)]):(_vm.status[item.status])?_c('v-chip',{staticClass:"font-weight-semibold py-3",attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")]):_vm._e()],1):_vm._e(),(_vm.$route.name.includes('admin'))?_c('div',[(_vm.status[item.status])?_c('v-chip',{staticClass:"font-weight-semibold py-3",class:{'mt-2': _vm.isTimer(item) && item.status == 5},attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")]):_vm._e(),(_vm.isTimer(item) && item.status == 5)?_c('count-down-timer',{staticClass:"mt-1",attrs:{"end":item.order_history[0].date_end,"color":"error","tooltip":'You need to resolve the dispute within ' + item.refund_expired_period + ' days'}}):_vm._e()],1):_vm._e()]}},{key:"item.eye",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"secondary","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{name: _vm.orderLink, params:{id: item.id}}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s('View'))])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-data-table
    :headers="getOrderRefHeadersByRouteName($route.name)"
    :items="orders"
    :options.sync="options"
    :server-items-length="totalOrders"
    :loading="loading"
    :footer-props="{
      'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
      'disable-pagination': loading,
      'disable-items-per-page': loading,
    }"
    :mobile-breakpoint="100"
    @update:items-per-page="updateItemsPerPage"
    @update:page="getOrdersFromApi"
    @update:sort-desc="getOrdersFromApi"
    :items-per-page="globalItemsPerPage"
  >
    <template v-slot:loading>
      <v-skeleton-loader type="table-row-divider@5" style="width: 90vw; max-width: 1390px"></v-skeleton-loader>
    </template>
    <template #no-data>
      <div class="mt-8 mb-8">
        <EmptyTableIcon />
        <p class="empty-title">{{ $t('Your list is empty.')}}</p>
        <v-btn v-if="isBuyer($route.name)" color="primary" :to="{name: 'products'}">
          {{ $t('Buy Deals') }}
        </v-btn>
        <create-product buttonText="Create Product" v-else-if="!$route.name.includes('refunds') && !$route.name.includes('admin')" />
      </div>
    </template>
    <template #[`header.source_link`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`header.buy_box_price`]="{header}">
      <span v-html="header.text"></span>
    </template>
    <template #[`item.id`]="{item}">
      <router-link :to="{name: relativeLink($route.name), params: {id: item.id}}">{{ item.id }}</router-link>
    </template>
    <template #[`item.dispute_id`]="{item}">
      <router-link :to="{name: relativeLink($route.name), params: {id: item.id}}">{{ item.dispute_id }}</router-link>
    </template>
    <template #[`item.dispute_date`]="{item}">
      <span>{{ item.dispute_date.slice(0, 10) }}</span>
    </template>
    <template #[`item.keepa_image`]="{item}">
      <router-link :to="{name: orderLink, params:{id: item.id}}">
        <v-img
          class="my-2"
          max-height="50"
          max-width="80"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;border-radius: 5px"
          :src="item.keepa_image||noKeepaImage"
        />
      </router-link>
    </template>
    <template #[`item.image`]="{item}">
      <router-link :to="{name: orderLink, params:{id: item.id}}">
        <v-img
          class="my-2"
          max-height="50"
          max-width="70"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;border-radius: 5px"
          :src="item.image"
        />
      </router-link>
    </template>
    <template #[`item.source_link`]="{item}">
      <div class="d-flex flex-column">
        <v-tooltip v-if="item.asin" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <a 
              v-bind="attrs"
              v-on="on"
              :href="item.product_url"
              target="_blank"
              style="width: fit-content"
            >
              {{ item.asin }}
            </a>
          </template>
          <span>{{ 'ASIN' }}</span>
        </v-tooltip>
        <v-tooltip max-width="330px" v-if="cutString(item.source_link).includes('...')" top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <a 
              v-bind="attrs"
              v-on="on"
              :href="item.source_link"
              target="_blank"
            >
              {{ cutString(item.source_link) }}
            </a>
          </template>
          <span>{{ item.source_link.slice(0, 100) }}</span>
        </v-tooltip>
        <v-tooltip v-else top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <a 
              v-bind="attrs"
              v-on="on"
              :href="item.source_link"
              target="_blank"
            >
              {{ cutString(item.source_link) }}
            </a>
          </template>
          <span>{{ $t('Source') }}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.seller`]="{item}">
      <router-link :to="{name: 'user', params: {id: item.seller?item.seller.user_id:'', tab: 'store'}}">{{ item.seller?item.seller.title:'' }}</router-link>
    </template>
    <template #[`item.product_id`]="{item}">
      <router-link :to="{name: 'product', params: {id: item.product_id}}">{{ item.product_id }}</router-link>
    </template>
    <template #[`item.store`]="{item}">
      <router-link :to="{name: 'store-products', params: {slug: item.store_slug}}">{{ item.store_title }}</router-link>
    </template>
    <template #[`item.buyer`]="{item}">
      <router-link :to="{name: 'user', params: {id: item.buyer?item.buyer.id:''}}">{{ item.buyer?item.buyer.name:'' }}</router-link>
    </template>
    <template #[`item.price`]="{item}">
      <v-list-item class="font-weight-semibold">{{ `$${Number(item.price)}` }}</v-list-item>
    </template>
    <template #[`item.buy_box_price`]="{item}">
      <div class="d-flex flex-column" style="font-size: 12px">
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ Number(item.buy_box_price) ? '$' + Number(item.buy_box_price) : $t("No BuyBox") }}</span>
          </template>
          <span>{{ 'BuyBox price'}}</span>
        </v-tooltip>
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><b>COG:</b> {{ `$${Number(item.cog)}` }}</span>
          </template>
          <span>{{ 'Cost of goods'}}</span>
        </v-tooltip>
        <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><b>3PL:</b> {{ Number(item.third_pl) ? `$${Number(item.third_pl)}` : '-' }}</span>
          </template>
          <span>{{ 'Third-party logistics'}}</span>
        </v-tooltip>
      </div>
    </template>
    <template #[`item.profit_fba`]="{item}">
      {{ Number(item.profit_fba) ? `$${Number(item.profit_fba)}` : '-' }}
    </template>
    <template #[`item.status`]="{item}">
    <div v-if="$route.name.includes('seller')">
      <v-chip
        v-if="status[item.status]"
        small
        :color="statusColor[status[item.status]]"
        class="font-weight-semibold py-3"
        :class="{'mt-2': isTimer(item) && item.status == 2}"
      >
        {{ status[item.status] }}
      </v-chip>
      <count-down-timer
        v-if="isTimer(item) && item.status == 2"
        :end="item.order_history[0].date_end"
        color="error"
        :tooltip="'You have ' + item.refund_expired_period + ' days to make a decision. Otherwise the order will be refunded automatically.'"
        class="mt-1"
      />
    </div>
    <div v-if="$route.name == 'orders-list' || $route.name == 'refunds-list'">
      <v-chip
        v-if="status[item.status] && item.status != 2 && item.status != 5 && item.status != 3"
        small
        :color="statusColor[status[item.status]]"
        class="font-weight-semibold py-3"
      >
        {{ status[item.status] }}
      </v-chip>
      <v-tooltip v-else-if="isTimer(item)"  max-width="250px" bottom color="secondary" open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-if="status[item.status]"
            small
            :color="statusColor[status[item.status]]"
            class="font-weight-semibold py-3"
            v-bind="attrs"
            v-on="on"
          >
            {{ status[item.status] }}
          </v-chip>
        </template>
        <span>
          <count-down-timer
            v-if="item.order_history[0]"
            :end="item.order_history[0].date_end"
            color="white"
            tooltip=""
          />
          <span>{{ getTimerTooltipBuyer(item) }}</span>
        </span>
      </v-tooltip>
      <v-chip
        v-else-if="status[item.status]"
        small
        :color="statusColor[status[item.status]]"
        class="font-weight-semibold py-3"
      >
        {{ status[item.status] }}
      </v-chip>
    </div>
    <div v-if="$route.name.includes('admin')">
      <v-chip
        v-if="status[item.status]"
        small
        :color="statusColor[status[item.status]]"
        class="font-weight-semibold py-3"
        :class="{'mt-2': isTimer(item) && item.status == 5}"
      >
        {{ status[item.status] }}
      </v-chip>
      <count-down-timer
        v-if="isTimer(item) && item.status == 5"
        :end="item.order_history[0].date_end"
        color="error"
        :tooltip="'You need to resolve the dispute within ' + item.refund_expired_period + ' days'"
        class="mt-1"
      />
    </div>
    </template>
    <template #[`item.eye`]="{item}">
      <v-tooltip top color="secondary" open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :to="{name: orderLink, params:{id: item.id}}"
            >
              <v-icon size="26">
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ 'View'}}</span>
        </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import {useRouter} from "@core/utils";
import RedFlags from "@/views/market/components/RedFlags";
import EmptyTableIcon from "@/views/components/icon/EmptyTableIcon";
import CreateProduct from "@/views/cabinet/product/CreateProduct";
import CountDownTimer from "@/views/cabinet/order/order-list/CountDownTimer";
import {ref, watch, computed} from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import store from "@/store";
import {mdiEyeOutline, mdiTimerOutline} from "@mdi/js";
import {isHaveTabs, kitCut, getOrderRefHeadersByRouteName, relativeLink, isBuyer} from "@/plugins/helpers"
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  name: "OrdersTable",
  props: {
    url: {
      type: String,
      default: false
    },
    orderLink: {
      type: String,
      default: false
    },
    userId: {
      type: String
    },
    storeId: {
      type: String
    }
  },
  components: {
    EmptyTableIcon,
    CountDownTimer,
    CreateProduct
  },
  setup(props) {
    const { menuIsVerticalNavMini } = useAppConfig()
    const {router, route} = useRouter()
    const adminStoreRoutes = [
      'admin-orders-store-list',
      'admin-refunds-store-list'
    ]
    const adminUserRefRoutes = [
      'admin-refunds-store-list',
      'admin-refunds-user-list'
    ]
    const adminLists = [
      'admin-orders-list',
      'admin-refunds-list',
    ]
    const sellerLists = [
      'seller-orders-list',
      'seller-refunds-list',
    ]
    const buyerLists = [
      'orders-list',
      'refunds-list',
    ]
    const options = computed(({
      get() {
        if(isHaveTabs()) return store.state.pagination[route.value.name][route.value.params.tab]
        return store.state.pagination[route.value.name]
      },
      set(val) {
        let page = val.page > val.lastPage ? 1 : val.page
        if(isHaveTabs()) {
          let ob = {}
          ob[route.value.params.tab] = Object.assign({}, val, {
            page,
            search: val.search ? val.search : route.value.query.search
          })
          store.commit("pagination/SET_" + route.value.name.replace(/-/gi, '_').toUpperCase() + "_PAGINATION", ob)
        }else {
          store.commit("pagination/SET_" + route.value.name.replace(/-/gi, '_').toUpperCase() + "_PAGINATION", Object.assign({}, val, {
            page,
            search: val.search ? val.search : route.value.query.search
          }))
        }
      }
    }))

    const cutString = (text, limit = menuIsVerticalNavMini.value ? 28 : 14) => kitCut(text, limit)

    const getOrdersFromApi = () => {
      store.dispatch('order/getOrdersFromApi', {
        url: props.url,
        userId: props.userId,
        storeId: props.storeId,
        data: options.value
      })
    }

    watch(() => route.value.params.tab, () => getOrdersFromApi())
    watch(() => route.value.name, () => getOrdersFromApi())

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])
    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e)
      getOrdersFromApi()
    }

    getOrdersFromApi()

    if(isHaveTabs()) {
      if(store.state.pagination[route.value.name][route.value.params.tab].search) {
        router.push({ name: route.value.name, query: { search: store.state.pagination[route.value.name][route.value.params.tab].search }})
      }
    }else {
      if(store.state.pagination[route.value.name].search) {
        router.push({ name: route.value.name, query: { search: store.state.pagination[route.value.name].search }})
      }
    }
    const getTimerTooltipBuyer = (item) => {
      switch (item.order_history[0].status) {
        //Refund requested
        case 2:
          return 'Seller will make a decision on your application within 3 days. Otherwise the refund will be issued automatically'
        //Declined by seller
        case 3:
          return 'You can appeal the seller\'s decision within ' + item.refund_period + ' days'
        //Escalated to admin
        case 5:
          return 'Administration will make a decision within ' + item.refund_expired_period + ' days'
      }
    }
    const isTimer = (item) => Math.trunc((new Date()).getTime() / 1000) < Math.trunc(Date.parse(item.order_history[0].date_end) / 1000)
    return {
      getTimerTooltipBuyer,
      isTimer,
      cutString,
      isBuyer,
      relativeLink,
      getOrderRefHeadersByRouteName,
      updateItemsPerPage,
      globalItemsPerPage,
      getOrdersFromApi,
      options,
      orders: computed(() => store.state.order.orders),
      totalOrders: computed(() => store.state.order.total),
      loading: computed(() => store.state.order.loading),
      icons: {
        mdiEyeOutline,
        mdiTimerOutline
      },
      status: {
        0: 'Unpaid',
        1: 'Paid',
        2: 'Refund Requested',
        3: 'Refund Declined',
        4: 'Refunded',
        5: 'Escalated to Admin',
        6: 'Refund Declined by Admin',
        7: 'Refunded by Admin'
      },
      statusColor: {
        /* eslint-disable key-spacing */
        Unpaid: '#8A8D93',
        Paid: 'success',
        'Refund Requested': sellerLists.includes(route.value.name) ? 'error' : 'warning',
        'Refund Declined': buyerLists.includes(route.value.name) ? 'error' : 'success',
        Refunded: buyerLists.includes(route.value.name) ? 'success' : '#8A8D93',
        'Escalated to Admin': adminLists.includes(route.value.name) ? 'error' : 'warning',
        'Refund Declined by Admin': buyerLists.includes(route.value.name) ? '#8A8D93' : 'success',
        'Refunded by Admin': buyerLists.includes(route.value.name) ? 'success' : '#8A8D93',
        /* eslint-enable key-spacing */
      },
      noKeepaImage: require('@/assets/images/app/pic_2.jpg'),
    }
  }
}
</script>
